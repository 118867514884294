import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Footer, Header }from '../common/sections'
import Banner from '../common/banner/landing'
import { ghostOfflineData } from '../../utils/configs/ghost'
import '../../assets/css/main.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

  
const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
      query={graphql`
          query GhostSettings {
              allGhostSettings {
                  edges {
                      node {
                          ...GhostSettingsFields
                      }
                  }
              }
              file(relativePath: {eq: "ghost-icon.png"}) {
                  childImageSharp {
                      fixed(width: 30, height: 30) {
                          ...GatsbyImageSharpFixed
                      }
                  }
              }
          }
      `}
      render={data => <DefaultLayout data={data} {...props} />}
  />
)

const DefaultLayout = ({data, children, location, bodyClass, isHome}) => {
  let site, navData, footerData, twitterUrl, facebookUrl
  try{
    site = data.allGhostSettings.edges[0].node
  }
  catch{
    console.error("GhostCMS offline. Procedding with offline data...")
    site = ghostOfflineData;
  }
  finally{
    navData = {navigation:site.navigation, title:site.title}
    footerData = {secondary_navigation:site.secondary_navigation}
    twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
  }
  console.log(site.codeinjection_head)
  // console.log(eval(site.codeinjection_head))

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="https://app.termly.io/embed.min.js" data-auto-block="on" data-website-uuid="0e854827-0b1a-4f9e-8ce1-a8463dee44cb" ></script>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <style type="application/javascript">{`${site.codeinjection_head}`}</style>
        <body className={bodyClass} />
      </Helmet>
      <div className="viewport">
        {isHome ? <Banner data={navData} isHome={isHome}/> : <Header data={navData} isHome={isHome}/>}
        <main id="main" className="site-main">
            {children}
        </main>
        <Footer data={footerData}/>
      </div>
    </>
  )
}


export default DefaultLayoutSettingsQuery;


  