/**
 * module.exports adds values to the export object (key-to-value object) to the module object in NodeJS
 * Learn more about module.export using the link below:
 * https://www.sitepoint.com/understanding-module-exports-exports-node-js/
 * @description Exports the variable that the site needs  
 */

 module.exports = {
    siteUrl: `https://fortydegreewaters.com`, // Site domain. Do not include a trailing slash!
    siteTitleMeta: `Forty Degree Water: Personal Travel`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Join us on our travels of the wild backcountry, national parks, state parks, road trips, cities, towns, and local attractions. Dive in.`, // This allows an alternative site description for meta data for pages.
    siteBlogPath: `/blog`,
    siteBlogTagPath: `/blog/tag`,
    sitePostPath: `/blog/post`,

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    postsPerPage: 9, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    shortTitle: `Forty Degree Waters`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `logo.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
    ghostOfflineData: {
        title: "ZILLENNIAL TRAVEL",
        timezone: "Etc/UTC",
        lang: `en`,
        navigation : [
            {
                label: "travel blog",
                url: "/blog"
            },
            {
                label: "photography",
                url: "/photography"
            }
        ],
        secondary_navigation: [
            {
                label: "about",
                url: "/about"
            },
            {
                label: "click me",
                url: "/click-me"
            },
            {
                label: "contact",
                url: "/contact"
            }
        ],
        description: "Thoughts, stories and ideas.",
        twitter: `f`,
        facebook: `facebook`
    }
}