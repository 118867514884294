import React, { useState, useCallback } from "react"
import { FaWindowClose } from "@react-icons/all-files/fa/FaWindowClose"
import Gallery from "../../../utils/plugin/gallary/Gallery"
import { Carousel } from "react-responsive-carousel"
import { IconContext } from "@react-icons/all-files"

/**
 * Formats photo portfolio page.
 * 
 * @fix Gallary plugin format to fix photo load speed
 * @var photos(obj): formatted as an object from the Gallary plugin
 * @link Gallary plugin: https://neptunian.github.io/react-photo-gallery/examples/lightbox.html
 * @return <div> HTML element. It's children are <Gallery/> and <PhotographyCarousel/>
 */
const PhotoPortfolio = ({ photos }) => {
    for (var i = 0; i < photos.length; i++) {
        /** DEBUG
         * 
         * @deprecated Commented code below makes width and height different, but it does not always work.
         */
        // photos[i].height = Math.floor(Math.random() * 5) + 2;
        // photos[i].width = Math.floor(Math.random() * 9) + 4;
        photos[i].height = 2
        photos[i].width = 3
        photos[i].alt = photos[i].title
        photos[i].sizes = "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
        // photos[i].srcset="large.jpg 1024w, medium.jpg 640w,small.jpg 320w"
    }

    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])
    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    return (
        <div className="photoportfolio">
            <Gallery photos={photos} columns={3} onClick={openLightbox} />
            {viewerIsOpen ? (
                <PhotographyCarousel
                    photos={photos}
                    currentImage={currentImage}
                    closeLightbox={closeLightbox} />
            ) : null}
        </div>
    )
}


/**
 *  Formats photo portfolio page
 *
 * @return
 */
const PhotographyCarousel = ({ photos, currentImage, closeLightbox }) => {
    return (
        <div className="modal-overlay">
            <div>
                <i onClick={closeLightbox}>
                    <IconContext.Provider value={{ size: "2.5em" }}>
                        <FaWindowClose />
                    </IconContext.Provider>
                </i>
            </div>
            <div className="modal-container">
                <div>
                    <Carousel
                        dynamicHeight={false}
                        infiniteLoop={true}
                        selectedItem={currentImage}
                        useKeyboardArrows={true}
                    >
                        {photos.map(node => (
                            <div>
                                <img
                                    style={{ height: "800px" }}
                                    src={node.src}
                                    alt={photos.title}
                                />
                                {photos.title ? (
                                    <div className="legend"> photos.title </div>
                                ) : null}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default PhotoPortfolio
