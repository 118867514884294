import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { IconContext } from "@react-icons/all-files";
import { FaGrimace } from "@react-icons/all-files/fa/FaGrimace";
import IMG_DEFAULT_AUTHOR from "../../../assets/images/default/author.png"


const AuthorCard = ({name, photo, url}) => {
    if(!photo){
      photo = IMG_DEFAULT_AUTHOR
    }
    return (
        <Link to={url}>
          <div className="authorcard">
            { 
                photo ? <img className="authorcard-image" src={photo} alt={name + " Avatar"} /> : 
                <div><IconContext.Provider value={{ size: "20em" }}> <FaGrimace/> </IconContext.Provider> </div>
            }
            {/* <div className="authorcard-image" style={{backgroundImage: `url(${photo})`,}}/> */}
            <h5 className="authorcard-name" >{name ? name : "No Name"}</h5>
          </div>
        </Link>
    );
}

export default AuthorCard