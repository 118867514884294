import React from 'react';
import { Link } from "gatsby"

import PropTypes from 'prop-types'
import { IconContext } from "@react-icons/all-files";
import { FaGrimace } from "@react-icons/all-files/fa/FaGrimace";

const ClickMeCard = ({title, icon, url}) => {
  return (
    <Link to={url}>
    <div className="clickmecard">
          {/* <div className="icon">
              <IconContext.Provider value={{ size: "1em" }}>
                {icon ? icon : <FaGrimace/>}
              </IconContext.Provider>
            </div> */}
            <h5>{title ? title : "Title"}</h5>
    </div>
    </Link>

    );
};

export default ClickMeCard;
