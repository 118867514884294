import React, {useRef} from 'react'
import IMG_LANDING from '../../../assets/images/landing2.jpg'
import { Header } from '../sections'
import { IconContext } from "@react-icons/all-files";
import { FaArrowCircleDown } from "@react-icons/all-files/fa/FaArrowCircleDown";
import { pagesConfig } from '../../../utils/configs/site';
import { shortTitle } from '../../../utils/configs/ghost';
import { unset } from 'lodash';
const Landing = ({data, isHome}) => {
    // const scrollDown = () => {
    //     document.getElementsByClassName("container")[0].children[0] = document.documentElement.scrollIntoView({behavior: "smooth"});
    // }
    return (
        // <div className="cover">
        //     {/* TODO: Add alt tag and SEO */}
        //     <img className="cover-image u-object-fit" src={IMG_LANDING}/>
        //     <div/>
        // </div>
    

        <div className="landing-hero">
            <div className="top">
                <Header data={data} isHome={true}/>
            </div>
            <div className="main-content">
                {/* TODO: Change to fetching actaully data */}
                <h1> {pagesConfig.index.banner.title} </h1>
                <h4> {pagesConfig.index.banner.subtitle} </h4>
            </div>
            <div className="slideshow">
                <picture>
                    <img src={IMG_LANDING} alt="Forty Degree Water Landing Image"/>
                </picture>
            </div >
           <div className="bottom" onClick={()=>document.getElementById('main').scrollIntoView({
  behavior: 'smooth'})}>

                <IconContext.Provider  value={{ size: "4em" }} >
                    <i className={{backgroundColor:'black'}}>
                        <FaArrowCircleDown className={{backgroundColor:'black'}}/>
                    </i>
                </IconContext.Provider>
                </div>

        </div>        
    )
}

export default Landing;
