import React from "react";
import { FaHashtag } from "@react-icons/all-files/fa/FaHashtag";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaPinterestSquare } from "@react-icons/all-files/fa/FaPinterestSquare";
import { SiTiktok } from "@react-icons/all-files/si/SiTiktok";
import { FaMapSigns } from "@react-icons/all-files/fa/FaMapSigns";
import { FaPenSquare } from "@react-icons/all-files/fa/FaPenSquare";
import { FaPhoneSquareAlt } from "@react-icons/all-files/fa/FaPhoneSquareAlt"
import { siteBlogPath } from "./ghost";
import { shortTitle } from "./ghost";
import IMG_DEFAULT from "../../assets/images/logo.jpg"


export const pagesConfig = {
    index: {
        banner: {
            title: shortTitle,
            subtitle: "Dive Into Our Travel Stories, Itineraries, and Tips"
        },
        highlightcards: [
            {
                title: "Blog",
                link: "/blog",
                image: {
                    src: IMG_DEFAULT,
                    alt: "Blog Page Image"
                }
            },
            {
                title: "Photography",
                link: "/photography",
                image: {
                    src: IMG_DEFAULT,
                    alt: "Photography Page Image"
                }
            },
            {
                title: "About",
                link: "/about",
                image: {
                    src: IMG_DEFAULT,
                    alt: "About Page Image"
                } 
            },
            {
                title: "Contact",
                link: "/contact",
                image: {
                    src: IMG_DEFAULT,
                    alt: "Contact Page Image"
                }
            }
        ]
    },
    blog: {
        categories : [
            // TODO: In the future convert categories into templates. Instead of having to make a through Gatsby everytime page everytime.
            {
                key: "tags",
                logo: <FaHashtag/>,
                url: siteBlogPath + `/category/tags`
            },
            {
                key: "authors",
                logo: <FaUser/>,
                url: siteBlogPath + `/category/authors`
            },
            {
                key: "destination",
                logo: <FaMapSigns/>,
                url: siteBlogPath + `/category/destination`
            }
        ],
        featuredPosts : {
            defaultCarouselImage: IMG_DEFAULT 
        }
    },
    clickme: {
        navigation : [
            {
              key: "check out our blog!",
              url: "/blog",
              icon: <FaPenSquare/>
            },
            {
              key: "contact us",
              url: "/contact",
              icon: <FaPhoneSquareAlt/>
            }
        ]
    }
}

export const sectionsConfig = {
    footer : {
        social_navigation : [
            {
                key: "Instagram",
                icon: <FaInstagram />,
                link: "https://www.instagram.com/fortydegreewaters"
            },
            {
                key: "Pinterest",
                icon: <FaPinterestSquare />,
                link: "https://pin.it/4b92q7F"
            },
            {
                key: "TikTok",
                icon: <SiTiktok />,
                link: "https://vm.tiktok.com/TTPdA8jY7K"
            }
        ]
    }
}

export const cardConfigs = {
    post: {
        defaultPostImage: IMG_DEFAULT
    }
    
}