import { Link } from 'gatsby'
import React from 'react'

const HighlightCard = ({title,image_src, image_alt, link}) => {
  return (
    <div className="index-highlights-card">
    <Link to={link}>
        <div className="index-highlights-card-body">
            <div className="index-highlights-card-title">{title}</div>
            <img className="index-highlights-card-image" src={image_src} alt={image_alt}/>
        </div>
    </Link>
    </div>

  )
}

export default HighlightCard