import { Link } from 'gatsby';
import React from 'react'
import { IconContext } from "@react-icons/all-files";
import { FaAngleUp } from "@react-icons/all-files/fa/FaAngleUp";
import { sectionsConfig } from '../../../utils/configs/site';
import { shortTitle } from '../../../utils/configs/ghost';

const Footer = ({data}) => {
    const secondary_navigation = data.secondary_navigation;
    return (
        <footer>
        <div className="footer">
            <LowerFooter secondary_navigation={secondary_navigation}/>
        </div>
            <LegalContent/>
        </footer>
    );
}

const LowerFooter = ({secondary_navigation}) => {
    let social_navigation = sectionsConfig.footer.social_navigation
    return (
        <section className="footer-lower">
            <hr className="divider"></hr>
            <nav className="footer-lower-socials">
                {
                    social_navigation && social_navigation.map((element) => (
                        <IconContext.Provider value={{ size: "2.0em" }}>
                            <i onClick={() => window.open(element.link).focus()}>
                                {element.icon}
                            </i>
                        </IconContext.Provider>
                    ))
                }
            </nav>
            <nav className="footer-lower-nav">
                {
                    secondary_navigation && secondary_navigation.map((element) => (
                        <Link
                            to={element.url}
                            className="nav-link"
                            activeClassName="active-link"
                        >
                            {element.label}
                        </Link>
                    ))
                }
            </nav>
            <div className="footer-scroll-up" role="button" onClick={() => {window.scroll({ top: 0, left: 0, behavior: 'smooth' });}}>
                <IconContext.Provider value={{ size: "1rem" }}>
                        <FaAngleUp />
                </IconContext.Provider>
                &ensp;
                <span>
                    Top
                </span>
            </div>
        </section>
    );
}

const LegalContent = () =>{
    return(
        <section className="footer-legal">

            <div className="copyright"> © {new Date().getFullYear()} {shortTitle} </div>
            <nav className="footer-legal-nav">
                <Link to="/disclaimer">Disclaimer</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/cookie-policy">Cookie Policy</Link>
                {/* <button
  class="termly-cookie-preference-button"
  type="button"
  style="background: white; width: 165px; height: 30px; border-radius: 3px; border: 1px solid #5f7d9c; font-family: Roboto, Arial; font-size: 10px; color: #5f7d9c; font-weight: 600; box-sizing: border-box; cursor: pointer; padding: 0; outline: none;"
  onclick="displayPreferenceModal()"
>
    Manage Cookie Preferences
</button> */}
  
            </nav>
        </section>
    );
}





export default Footer
