import React, { useState } from 'react'
import { Link } from "gatsby"
import { IconContext } from "@react-icons/all-files";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import IMG_LOGO from "../../../../static/logo.png"

const Header = ({ data, isHome }) => {
    const [showBarNav, setShowBarNav] = useState(false);
    const title = data.title;
    const navigation = data.navigation
    const NavbarElement = ({ element }) => {
        return (
            <Link to={element.url} className="nav-link" activeClassName="active-link">
                {element.label}
            </Link>
        );
    };

    const DefaultHeader = () => {
        return (
            <header className="header">
                <div className="header-container">
                    <section className="header-brand">
                        <Link to="/" style={{display:"flex",alignItems: "center",gap:"10px"}}> <img src={IMG_LOGO} alt={"Forty Degree Water Logo"}style={{height:"7rem"}}/>{title}</Link>
                    </section>
                    <nav>
                        {
                            navigation && navigation.map((element) => (<NavbarElement element={element} />))
                        }
                    </nav>
                    <section className="header-actions"/>
                    <section className="main" >
                        <Link to="/"> {title}</Link>
                        <div className="vrq-42f">
                            <IconContext.Provider value={{ size: "1em" }}>
                                <i onClick={() => { setShowBarNav(!showBarNav) }} onTouch={() => {setShowBarNav(false)}}>
                                    <FaBars />
                                </i>
                            </IconContext.Provider>
                            {
                                showBarNav ? <nav className="barnav">
                                {navigation && navigation.map((element) => (<NavbarElement element={element} />))}
                                </nav> : null
                            }
                        </div>
                    </section>
                </div>
            </header>
        );
    }

    const LandingHeader = () => {
        return (
            <nav>
                {navigation && navigation.map((element) => (<NavbarElement element={element} />))}
            </nav>
        );
    }
    return isHome ? <LandingHeader /> : <DefaultHeader />;
}

export default Header