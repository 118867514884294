import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { sitePostPath } from '../../../utils/configs/ghost'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { cardConfigs } from '../../../utils/configs/site';


function assignPhotographyPostFeatureImage(featureImage, photographyGallaryList){
    // if(!featureImage && post.codeinjection_head){
    if(photographyGallaryList && photographyGallaryList[0]){
        featureImage = photographyGallaryList[0].src
    }
    return featureImage
   
    //THIS THE OLD WAY OF CHECKING THE FEATURE IMAGE SHOULD BE FROM A PHOTOGRAY POST
    // if(!featureImage && post.codeinjection_head){
    //     codeInjectionHead = eval(post.codeinjection_head)
    //     console.log(codeInjectionHead)
    //     if(codeInjectionHead[0]){
    //         featureImage = codeInjectionHead[0].src
    //     }
    // }else{
    //     featureImage = post.feature_image
    // }
}

const PostCard = ({ post }) => {
    const url = sitePostPath + `/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    let codeInjectionHead, featureImage 

    let photographyGallaryList = eval(post.codeinjection_head)
    featureImage = assignPhotographyPostFeatureImage(post.feature_image, photographyGallaryList)
    return (
        <Link to={url} className="post-card">
               {/* <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {
                            post.primary_author.profile_image ?
                            <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                            <FaUser alt={post.primary_author.name}/>
                        }
                    </div>
                    <span>{ post.primary_author.name }</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer> */}
            <header className="post-card-header">
                {
                    featureImage ?
                    <div className="post-card-image" style={{backgroundImage: `url(${featureImage})`,}}/>:
                    <div className="post-card-image" style={{backgroundImage: `url(${cardConfigs.post.defaultPostImage})`,}}/>
                }
                    <div style={{display:'flex', alignItems:'center'}}>
                            <span>By: { post.primary_author.name }</span>
                        </div>
                    <div className="post-card-tags"> 
                        {post.tags ? <Tags post={post} visibility="public" autolink={false} />  : null}
                       
                    </div>
                <div className="post-card-title">
                    <h3>{post.title}</h3>
                </div>
            </header>
            <section className="post-card-excerpt">
                <p >{post.excerpt}</p>
            </section>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
