import React from 'react'
import { HighlightCard } from '../cards'
import { pagesConfig } from '../../../utils/configs/site'
// import "../../../assets/css/bulma-css.scss"


const IndexHighlights = () => {
  return (
    <div className="index-highlights">
    <h1>Highlights </h1>
    <div className="index-highlights-cards-section" >
        {pagesConfig.index.highlightcards.map((element) => (
        <HighlightCard title={element.title} image_src={element.image.src} image_alt={element.image.alt} link={element.link}/>
        ))}
    </div>
    </div>
  )
}

export default IndexHighlights