import React from 'react';
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import { IconContext } from "@react-icons/all-files";
import { FaGrimace } from "@react-icons/all-files/fa/FaGrimace";

const CategoryCard = ({title, logo, url}) => {
  return (
    <Link to={url}>
      <div className="categorycard">
        <div className="icon">
          <IconContext.Provider value={{ size: "5em" }}>
            {logo ? logo : <FaGrimace/>}
          </IconContext.Provider>
        </div>
        <h5>{title ? title : "Title"}</h5>
      </div>
    </Link>
  );
};

export default CategoryCard;
